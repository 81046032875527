import ReportRecord from "../components/ReportRecord";
import {
  getMomentsDetail,
  reportRecordList,
  getCommentList,
  momentsOperate,
  commentMaskOrPass,
} from "@/api/friendCircle";

export default {
  components: {
    ReportRecord,
  },
  data() {
    return {
      reportList: [],
      commentList: [],
      commentPageIndex: 1,
      info: {
        auditStatus: "",
        browseNum: "",
        commentNum: "",
        dynamicAddress: "",
        goodsNum: "",
        isShield: false,
        pictureList: [],
        releaseTime: "",
        shareNum: "",
        subject: "",
        title: "",
        topicId: "",
        userAvatarUrl: "",
        userId: "",
        userName: "",
        fileType: 0,
        heat: "",
        subjectList:[],
        product:{}
      },
      reportEmpty: false,
      opened: false,
      commentEmpty: false,
    };
  },
  computed: {
    id() {
      return this.$route.query.id || "";
    },
  },
  created() {
    if (this.id) {
      this.getDetail();
      this.getReportRecordList();
      this.getComments();
    }
  },

  methods: {
    getDetail() {
      const param = { data: this.id };

      getMomentsDetail(param)
        .then((res) => {
          if (!res.data) return;
          this.info = { ...res.data };
        })
        .catch((err) => console.log(err));
    },

    getReportRecordList() {
      const param = {
        pageNumber: 1,
        pageSize: 500,
        data: { topicId: this.id, reportType: 0 },
      };

      reportRecordList(param)
        .then((res) => {
          if (!res.data) return;
          this.reportList = res.data.content || [];

          if (res.data.empty) {
            this.reportEmpty = true;
          }
          console.log(this.reportEmpty);
        })
        .catch((err) => console.log(err));
    },

    getComments() {
      const param = {
        pageNumber: this.commentPageIndex,
        pageSize: 10,
        data: { topicId: Number(this.id) },
      };

      getCommentList(param)
        .then((res) => {
          if (!res.data) return;
          const arr = res.data.content || [];
          arr.forEach((parent) => {
            parent.children.forEach((el, index) => {
              el.isOpened = index < 4;
            });
          });

          this.commentList = arr;

          if (res.data.empty) {
            this.commentEmpty = true;
          }
        })
        .catch((err) => console.log(err));
    },

    openAll(index) {
      this.commentList[index].children.forEach((el) => {
        el.isOpened = true;
      });

      this.$set(this.commentList, index, this.commentList[index]);
    },

    passMoments() {
      const reqObj = {
        data: {
          topicId: this.id,
          check: 1,
        },
      };

      momentsOperate(reqObj).then((res) => {
        if (res.success) {
          this.$message({
            message: "审核通过",
            type: "success",
          });

          this.getDetail();
        }
      });
    },

    maskMoments() {
      const reqObj = {
        data: {
          topicId: this.id,
          check: 2,
        },
      };

      momentsOperate(reqObj).then((res) => {
        if (res.success) {
          this.$message({
            message: "屏蔽成功",
            type: "success",
          });

          this.getDetail();
        }
      });
    },

    maskComment(commentId) {
      const reqObj = {
        data: {
          commentId: Number(commentId),
          isShield: 1,
        },
      };

      commentMaskOrPass(reqObj).then((res) => {
        if (res.success) {
          this.$message({
            message: "屏蔽成功",
            type: "success",
          });

          this.getComments();
        }
      });
    },

    cancelMaskComments(commentId) {
      const reqObj = {
        data: {
          commentId: Number(commentId),
          isShield: 0,
        },
      };

      commentMaskOrPass(reqObj).then((res) => {
        if (res.success) {
          this.$message({
            message: "已取消屏蔽",
            type: "success",
          });

          this.getComments();
        }
      });
    },
  },
};
