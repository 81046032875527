<template>
  <div class="moements-detail">
    <div class="left">
      <div class="info-content">
        <span class="info-area">
          <div class="top-info">
            <div class="first-row">
              <span v-if="info.title" class="title">{{ info.title }}</span>
              <span v-if="!info.title" class="no-title">{{
                info.subject.length > 29
                  ? info.subject.slice(0, 29) + "..."
                  : info.subject
              }}</span>
              <span
                v-if="
                  info.auditStatus == 0 &&
                  info.isReport == 0 &&
                  info.isShield == 0
                "
                class="state"
                style="background: #d77af2"
                >待审核</span
              >
              <span
                v-if="info.isReport == 1 && info.isShield == 0"
                class="state"
                style="background: #f5561a"
                >被举报</span
              >
              <span
                v-if="
                  info.auditStatus == 1 &&
                  info.isReport == 0 &&
                  info.isShield == 0
                "
                class="state"
                style="background: #13beb5"
                >已通过</span
              >
              <span
                v-if="info.isShield == 1"
                class="state"
                style="background: #854343"
                >人工屏蔽</span
              >
              <span
                v-if="info.isShield == 2"
                class="state"
                style="background: #803f68"
                >机器屏蔽</span
              >
            </div>

            <div class="second-row">
              <span>作者：</span>

              <el-image
                class="avatar"
                :src="info.userAvatarUrl || require('@/assets/img/moment.jpg')"
              ></el-image>

              <span style="margin-left: 5px"
                >{{ info.userName }} &nbsp;| &nbsp;作者ID：{{
                  info.userId
                }}</span
              >
            </div>

            <div class="third-row">
              <i class="el-icon-time"></i>
              <span>发布时间：{{ info.releaseTime }}</span>

              <i class="el-icon-location-outline"></i>
              <span>位置：{{ info.dynamicAddress }}</span>
            </div>
          </div>

          <div class="bottom-info">
            <span class="data-item">
              <svg class="icon" viewBox="0 0 1024 1024" width="16" height="16">
                <path
                  d="M448.533333 155.797333c67.968 53.098667 115.498667 104.618667 142.592 154.602667 25.664 47.36 35.968 95.808 30.912 145.344l-0.981333 8.256 5.034667-4.117333a143.829333 143.829333 0 0 0 40.533333-57.536l2.218667-6.122667 6.336-19.029333c67.776 46.037333 101.674667 124.010667 101.674666 233.898666 0 164.821333-144.426667 270.037333-263.125333 270.037334-118.72 0-253.866667-70.506667-275.733333-218.069334-21.845333-147.541333 68.757333-216.426667 130.474666-312.533333 41.173333-64.064 67.84-128.981333 80.064-194.730667z"
                  p-id="5138"
                  fill="#999999"
                ></path>
              </svg>
              <span class="text">热度</span>
              <span class="num">{{ info.heat }}</span>
            </span>

            <span class="data-item"
              ><i style="width: 15px" class="el-icon-view icon"></i>
              <span class="text">浏览</span>
              <span class="num">{{ info.browseNum }}</span></span
            >

            <span class="data-item">
              <svg class="icon" viewBox="0 0 1024 1024" width="16" height="16">
                <path
                  d="M157.568 751.296c-11.008-18.688-18.218667-31.221333-21.802667-37.909333A424.885333 424.885333 0 0 1 85.333333 512C85.333333 276.362667 276.362667 85.333333 512 85.333333s426.666667 191.029333 426.666667 426.666667-191.029333 426.666667-426.666667 426.666667a424.778667 424.778667 0 0 1-219.125333-60.501334 2786.56 2786.56 0 0 0-20.053334-11.765333l-104.405333 28.48c-23.893333 6.506667-45.802667-15.413333-39.285333-39.296l28.437333-104.288z m65.301333 3.786667l-17.258666 63.306666 63.306666-17.258666a32 32 0 0 1 24.522667 3.210666 4515.84 4515.84 0 0 1 32.352 18.944A360.789333 360.789333 0 0 0 512 874.666667c200.298667 0 362.666667-162.368 362.666667-362.666667S712.298667 149.333333 512 149.333333 149.333333 311.701333 149.333333 512c0 60.586667 14.848 118.954667 42.826667 171.136 3.712 6.912 12.928 22.826667 27.370667 47.232a32 32 0 0 1 3.338666 24.714667z m145.994667-70.773334a32 32 0 1 1 40.917333-49.205333A159.189333 159.189333 0 0 0 512 672c37.888 0 73.674667-13.173333 102.186667-36.885333a32 32 0 0 1 40.917333 49.216A223.178667 223.178667 0 0 1 512 736a223.178667 223.178667 0 0 1-143.136-51.690667z"
                  p-id="8136"
                  fill="#999999"
                ></path>
              </svg>

              <span class="text">评论</span>
              <span class="num">{{ info.commentNum }}</span>
            </span>

            <span class="data-item">
              <svg class="icon" viewBox="0 0 1024 1024" width="16" height="16">
                <path
                  d="M510.68 883.15c-15.2 0-30.37-8.49-48.1-25.47-25.35-24.3-50.81-48.48-76.27-72.64-61.6-58.49-125.28-118.96-186.38-180.25-68.39-68.6-99.26-141.23-94.39-222.07 4.09-67.79 31.08-122.65 78.06-158.66 50.62-38.79 123.3-53.23 194.46-38.6 51.71 10.63 90 41.18 127.03 70.72l1.54 1.23c0.47 0.38 0.94 0.76 1.41 1.13 8.05-5.05 15.94-10.15 23.68-15.13 30.01-19.35 58.34-37.63 90.38-50.54 84.26-33.9 189.34-8.11 244.51 60.07 58.08 71.79 68.23 157.45 28.57 241.22-20 42.22-50.67 84.68-91.16 126.22-57.91 59.41-118.94 117.32-177.96 173.33-22.3 21.16-44.59 42.32-66.77 63.59-17.98 17.22-33.31 25.85-48.61 25.85zM322.91 235.07c-40.08 0-77.72 11.4-105.7 32.85-34.37 26.34-53.38 66.08-56.52 118.12-3.92 65.06 20.98 122.17 78.37 179.73 60.56 60.74 123.97 120.95 185.3 179.18 25.52 24.23 51.04 48.47 76.45 72.82 4.78 4.57 7.91 7.21 9.9 8.73 2.06-1.55 5.31-4.3 10.31-9.09 22.25-21.35 44.61-42.57 66.98-63.8 58.64-55.64 119.28-113.19 176.44-171.82 36.23-37.15 63.39-74.6 80.77-111.3 30.5-64.42 23.05-127.64-21.58-182.8-39.86-49.23-119.33-68.36-180.89-43.55-27.16 10.93-53.36 27.83-81.09 45.71-13.56 8.74-27.59 17.79-42.49 26.65l-17.29 10.29-18.13-16.06c-2.81-2.58-7.07-6.51-11.59-10.12l-1.54-1.22c-33.41-26.65-64.96-51.83-103.69-59.79a217.005 217.005 0 0 0-44.01-4.53z"
                  p-id="7134"
                  fill="#999999"
                ></path>
              </svg>
              <span class="text">喜欢</span>
              <span class="num">{{ info.goodsNum }}</span>
            </span>

            <span class="data-item">
              <svg class="icon" viewBox="0 0 1024 1024" width="16" height="16">
                <path
                  d="M928 480c-17.664 0-32 14.336-32 32l0 320c0 17.664-14.336 32-32 32L160 864c-17.664 0-32-14.336-32-32L128 288c0-17.664 14.336-32 32-32l224 0 0 0c0 0 0 0 0 0 17.664 0 32-14.336 32-32s-14.336-32-32-32c-1.024 0-1.888 0.48-2.88 0.576L160.384 192.576c-52.96 0-95.904 43.008-95.904 96.064l0 543.872c0 53.056 42.944 96.064 95.904 96.064l704.224 0c52.96 0 95.904-43.008 95.904-96.064L960.512 512 960 512C960 494.336 945.664 480 928 480zM402.688 620.768c-1.92 4.128-3.168 8.608-3.168 13.44 0 17.664 14.336 32 32 32 15.456 0 27.744-11.168 30.752-25.76l0.64 0c37.12-189.696 193.984-336.256 388.672-358.272l-67.776 67.776c-12.512 12.512-12.512 32.768 0 45.248 12.512 12.512 32.768 12.512 45.248 0l122.528-122.528c12.512-12.512 12.512-32.768 0-45.248l-122.528-122.528c-12.512-12.512-32.768-12.512-45.248 0-12.512 12.512-12.512 32.768 0 45.248l67.52 67.52C628.256 239.872 448.224 405.408 402.688 620.768z"
                  p-id="9130"
                  fill="#999999"
                ></path>
              </svg>
              <span class="text">分享</span>
              <span class="num">{{ info.shareNum }}</span></span
            >
          </div>
        </span>
      </div>

      <div class="moments-content">
        <div v-if="info.fileType === 1" class="moments-video">
          <video
            v-for="(item, index) in info.pictureList"
            :key="index"
            class="video"
            :style="item.picType ? 'display:none' : ''"
            :src="item.picUrl"
            controls
            :poster="info.pictureList.find((el) => el.picType == 1).picUrl"
          ></video>
        </div>

        <div v-else class="moments-image">
          <el-image
            v-for="item in info.pictureList"
            :key="item.picUrl"
            class="image"
            fit="cover"
            :src="item.picUrl"
            :preview-src-list="[item.picUrl]"
          ></el-image>
        </div>

        <div v-if="info.title" class="moments-item">
          <div class="title-row">标题</div>
          <div class="item-content">{{ info.title }}</div>
        </div>

        <div class="moments-item">
          <div class="title-row">正文内容</div>
          <div class="item-content">
            {{ info.subject }}
          </div>
        </div>


        <div v-if="info.product" class="moments-item">
          <div class="title-row">关联商品</div>
          <div class="item-content">
            <div class="goods-card">
              <div style="margin-right: 12px">
                <img
                  width="68px"
                  height="68px"
                  :src="info.product.coverPictureUrl"
                />
              </div>
              <div class="card-right">
                <div style="font-size: 14px">
                  {{ info.product.productName }}
                </div>
                <div style="color: #b5b8bb">
                  产品ID：{{ info.product.productCode }}
                </div>
                <div>￥{{ info.product.salePrice }}</div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="info.subjectList.length != 0" class="moments-item">
          <div class="title-row">话题</div>
          <div class="item-content">
            <div class="tags">
              <span
                v-for="item in info.subjectList"
                :key="item.subjectId"
                class="tag"
                >#{{ item.subjectName }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="comment-content">
        <div class="title-row">评论（{{ info.commentNum }}条）</div>

        <div v-if="!commentEmpty" class="comment-container">
          <div
            v-for="(item, index) in commentList"
            :key="item.commentId"
            class="comment-item"
          >
            <div class="one-level">
              <span class="avatar">
                <el-image
                  class="image"
                  :src="item.avatarUrl || require('@/assets/img/moment.jpg')"
                ></el-image>
              </span>

              <span class="info-comment">
                <div class="first-row">
                  <span class="userName">{{ item.userName }}</span>

                  <span class="userId">用户ID：{{ item.userId }}</span>
                </div>
                <div class="second-row">
                  <span
                    class="subject-container"
                    :style="
                      item.isDelete || item.isShield
                        ? 'background: #fbdfda'
                        : ''
                    "
                  >
                    <span class="comment-text">{{ item.subject }}</span>

                    <el-image
                      class="thumb"
                      :src="require('@/assets/img/thumb.png')"
                    ></el-image>

                    <span class="thumb-num">{{ item.goodsNum }}</span>
                  </span>

                  <el-button
                    v-if="item.isShield == 1"
                    type="danger"
                    plain
                    size="small"
                    @click="cancelMaskComments(item.commentId)"
                    >取消屏蔽</el-button
                  >
                  <span v-else-if="item.isDelete" class="tips">已删除</span>
                  <span v-else-if="item.isShield == 2" class="tips"
                    >机器屏蔽</span
                  >
                  <el-button
                    v-else
                    type="danger"
                    plain
                    size="small"
                    @click="maskComment(item.commentId)"
                    >屏蔽</el-button
                  >
                </div>

                <div class="third-row">
                  {{ item.createTime.replace("T", " ") }}
                </div>

                <template
                  v-for="son in item.children.filter((el) => el.isOpened) || []"
                >
                  <template v-if="!son.replyUserId">
                    <div :key="son.commentId" class="second-level">
                      <span class="avatar">
                        <el-image
                          class="image"
                          :src="
                            son.avatarUrl || require('@/assets/img/moment.jpg')
                          "
                        ></el-image>
                      </span>

                      <span class="info-comment">
                        <div class="first-row">
                          <span class="userName">{{ son.userName }}1111</span>

                          <span class="userId">作者</span>
                        </div>

                        <div class="second-row">
                          <span
                            class="subject-container"
                            :style="
                              son.isDelete || son.isShield
                                ? 'background: #fbdfda'
                                : ''
                            "
                          >
                            <span class="comment-text">{{ son.subject }}</span>

                            <el-image
                              class="thumb"
                              :src="require('@/assets/img/thumb.png')"
                            ></el-image>

                            <span class="thumb-num">{{ son.goodsNum }}</span>
                          </span>

                          <el-button
                            v-if="son.isShield == 1"
                            type="danger"
                            plain
                            size="small"
                            @click="cancelMaskComments(son.commentId)"
                            >取消屏蔽</el-button
                          >
                          <span v-else-if="son.isDelete" class="tips"
                            >已删除</span
                          >
                          <span v-else-if="son.isShield == 2" class="tips"
                            >机器屏蔽</span
                          >
                          <el-button
                            v-else
                            type="danger"
                            plain
                            size="small"
                            @click="maskComment(son.commentId)"
                            >屏蔽</el-button
                          >
                        </div>

                        <div class="third-row">
                          {{ son.createTime.replace("T", " ") }}
                        </div>
                      </span>
                    </div>
                  </template>

                  <template v-if="son.replyUserId">
                    <div :key="son.commentId" class="second-level2">
                      <span class="avatar">
                        <el-image
                          class="image"
                          :src="
                            son.avatarUrl || require('@/assets/img/moment.jpg')
                          "
                        ></el-image>
                      </span>

                      <span class="info-comment">
                        <div class="first-row">
                          <span class="userName">{{ son.userName }}</span>

                          <span class="userId">用户ID：{{ son.userId }}</span>
                          <!-- <span class="userId">作者</span> -->
                        </div>

                        <div class="second-row">
                          <span class="reply">
                            <span>回复</span>
                            <span class="reply-userName">{{
                              son.replyUserName
                            }}</span>
                          </span>

                          <span
                            class="subject-container"
                            :style="
                              son.isDelete || son.isShield
                                ? 'background: #fbdfda'
                                : ''
                            "
                          >
                            <span class="comment-text">{{ son.subject }}</span>

                            <el-image
                              class="thumb"
                              :src="require('@/assets/img/thumb.png')"
                            ></el-image>

                            <span class="thumb-num">{{ son.goodsNum }}</span>
                          </span>

                          <el-button
                            v-if="son.isShield == 1"
                            type="danger"
                            plain
                            size="small"
                            @click="cancelMaskComments(son.commentId)"
                            >取消屏蔽</el-button
                          >
                          <span v-else-if="son.isDelete" class="tips"
                            >已删除</span
                          >
                          <span v-else-if="son.isShield == 2" class="tips"
                            >机器屏蔽</span
                          >
                          <el-button
                            v-else
                            type="danger"
                            plain
                            size="small"
                            @click="maskComment(son.commentId)"
                            >屏蔽</el-button
                          >
                        </div>

                        <div class="third-row">
                          {{ son.createTime.replace("T", " ") }}
                        </div>
                      </span>
                    </div>
                  </template>
                </template>
              </span>
            </div>

            <div
              v-if="
                item.children.length > 4 &&
                item.children.some((el) => !el.isOpened)
              "
              class="open-reply"
              @click="openAll(index)"
            >
              <span style="font-size: 12px; color: #777777"
                >展开全部{{ item.children.length - 4 }}条回复</span
              >
              <i class="el-icon-arrow-right" style="font-size: 10px"></i>
            </div>
          </div>
        </div>

        <div v-if="commentEmpty" class="comment-empty">
          <span>暂无评论~</span>
        </div>
      </div>

      <div class="bottom-button">
        <el-button
          v-if="
            !(
              info.auditStatus == 1 &&
              info.isReport == 0 &&
              info.isShield == 0
            ) ||
            (info.isShield != 2 &&
              !(
                info.auditStatus == 1 &&
                info.isReport == 0 &&
                info.isShield == 0
              ))
          "
          type="primary"
          size="small"
          @click="passMoments"
          >通过</el-button
        >
        <el-button
          v-if="info.isShield == 0"
          type="danger"
          plain
          size="small"
          @click="maskMoments"
          >屏蔽</el-button
        >
      </div>
    </div>

    <div v-if="!reportEmpty" class="right">
      <div class="title">
        <span class="text-row">动态举报记录</span>
      </div>

      <ReportRecord style="overflow-y: auto" :list="reportList"></ReportRecord>
    </div>

    <div v-if="reportEmpty" class="right" style="height: 380px">
      <div class="title">
        <span class="text-row">动态举报记录</span>
      </div>

      <el-empty class="empty" description="无动态举报记录~"></el-empty>
    </div>
  </div>
</template>

<script>
import indexScript from "./index.js";

export default {
  ...indexScript,
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
