<template>
  <div class="report-record">
    <el-steps direction="vertical">
      <el-step v-for="item in list" :key="item.id">
        <div slot="icon" class="step-icon"></div>

        <div slot="title" class="step-title">
          <span class="time">{{ item.createTime }}</span>
          <el-image class="avatar" :src="item.reportUserAvatarUrl || require('@/assets/img/moment.jpg')"></el-image>

          <span style="margin-left: 5px; font-size: 12px; color: #b5b8bb"
            >{{ item.reportUserName }} &nbsp;| &nbsp;用户ID：{{ item.reportUserId }}</span
          >
        </div>

        <div slot="description" class="step-description">
          <span class="tag-text">{{ item.reportContent }}</span>

          <div class="report-description">
            <span class="report-title">举报描述：</span>
            <span class="report-content">
              {{ item.reportDec }}
            </span>
          </div>
        </div>
      </el-step>
    </el-steps>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>
.report-record {
  margin-left: 24px;
  margin-top: 24px;

  .step-title {
    .time {
      font-size: 14px;
      color: #000000;
    }

    .avatar {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      margin-left: 16px;
      bottom: -2px;
    }
  }

  .step-description {
    display: inline-flex;
    flex-direction: column;

    .tag-text {
      color: #e86247;
      font-size: 14px;
      font-weight: 400;
      border-radius: 4px;
      border: 1px solid rgba(232, 98, 71, 0.36);
      background: #fcefec;
      display: inline-block;
      padding: 5px 14px;
      box-sizing: border-box;
      width: fit-content;
      line-height: 1;
    }

    .report-description {
      display: flex;
      margin-top: 10px;

      .report-title {
        font-size: 14px;
        color: #000000;
        font-weight: 400;
        width: 70px;
      }

      .report-content {
        font-weight: 400;
        color: #000000;
        font-size: 14px;
        flex: 1;
      }
    }
  }

  ::v-deep .el-step__head.is-wait {
    position: relative;
    width: 11px;
  }

  ::v-deep .el-step__icon.is-text {
    position: absolute;
    top: 6px;
  }

  ::v-deep .el-step__icon {
    width: 11px;
    height: 11px;
    border: 1px solid #2861e2;
  }

  ::v-deep .el-step.is-vertical .el-step__line {
    width: 1px;
    top: 13px;
    bottom: -6px;
    position: absolute;
    left: 5px;
  }

  ::v-deep .el-step.is-vertical .el-step__main {
    padding-left: 20px;
  }

  ::v-deep .el-step:last-of-type .el-step__main .el-step__description.is-wait,
  .el-step:last-of-type .el-step__main .el-step__description.is-wait {
    padding-right: 10%;
  }

  ::v-deep .el-step.is-vertical .el-step__title {
    padding-right: 10%;
  }

  ::v-deep .el-step__description.is-wait {
    margin-bottom: 30px;
  }

  ::v-deep .el-step:last-of-type.is-flex {
    .el-step__description {
      margin-bottom: 0;
    }
  }
}
</style>
